<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("menu.modules") }}
        </router-link>
        <div class="float-end" v-if="table.data.length">
            <el-tooltip :content="$t('common.exportTooltip')" popper-class="max-w-300px" placement="bottom">
                <button class="btn btn-danger align-self-center ms-3" :data-kt-indicator="exportLoading ? 'on' : null" :disabled="exportLoading" v-on:click="exportRequest">
                    <span v-if="!exportLoading" class="indicator-label">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/files/fil021.svg" />
                        </span>
                        {{ $t("common.export") }}
                    </span>

                    <span v-if="exportLoading" class="indicator-progress">
                        <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                        {{ $t("messages.wait") }}
                    </span>
                </button>
            </el-tooltip>
        </div>
    </div>

    <custom-table
        :title="$t('pages.module.teacherBookRequest.title')"
        :subTitle="$t('pages.module.teacherBookRequest.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleTeacherBookRequestFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:email="{ row: record }">
            <a :href="'mailto:' + record.email" class="text-gray-600 text-hover-primary mb-1">
                {{ record.email }}
            </a>
        </template>
        <template v-slot:phone="{ row: record }">
            <a :href="'tel:' + record.mobile_phone" class="text-gray-600 text-hover-primary mb-1">
                {{ record.mobile_phone }}
            </a>
        </template>
        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip v-if="record.upload" :content="$t('pages.module.teacherBookRequest.detail')" popper-class="max-w-300px" placement="bottom">
                    <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <el-tooltip v-if="record.upload" :content="$t('pages.module.teacherBookRequest.cols.file')" popper-class="max-w-300px" placement="bottom">
                    <a :href="record.upload.public_url" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" target="_blank">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/files/fil024.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>

    <div class="modal fade" id="kt_modal_request_detail" ref="requestDetailModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_request_detail_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_request_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.firstname') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.firstname }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.lastname') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.lastname }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.email') }}:</label>
                            <a :href="'mailto:'+ form.data.email" class="fw-bold fs-6 text-gray-500 text-hover-primary">{{ form.data.email }}</a>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.phone') }}:</label>
                            <a :href="'tel:'+ form.data.mobile_phone" class="fw-bold fs-6 text-gray-500 text-hover-primary">{{ form.data.mobile_phone }}</a>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.corporationName') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.corporation_name }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.corporationAddress') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.corporation_address }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.state') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ ((form.data.state) ? form.data.state.name : "-") }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.city') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ ((form.data.city) ? form.data.city.name : "-") }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.branch') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ form.data.branch }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.publisher') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ ((form.data.publisher && form.data.publisher.trim().length) > 0 ? form.data.publisher : "-") }}</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.file') }}:</label>
                            <a v-if="form.data.upload" :href="form.data.upload.public_url" class="fw-bold fs-6 text-gray-500 text-hover-primary">{{ form.data.upload.public_url }}</a>
                            <span v-else class="text-gray-500 fw-bold fs-6">-</span>
                        </div>
                        <div class="fv-row mb-3">
                            <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.module.teacherBookRequest.cols.createdAt') }}:</label>
                            <span class="text-gray-500 fw-bold fs-6">{{ $moment(form.data.created_at).format("DD.MM.YYYY - HH:mm:ss") }}</span>
                        </div>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.requestDetailModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.teacherBookRequest.cols.firstname"),
                    key: "firstname",
                },
                {
                    name: this.$t("pages.module.teacherBookRequest.cols.lastname"),
                    key: "lastname",
                },
                {
                    name: this.$t("pages.module.teacherBookRequest.cols.email"),
                    scopedSlots: {customRender: "email"}
                },
                {
                    name: this.$t("pages.module.teacherBookRequest.cols.phone"),
                    scopedSlots: {customRender: "phone"}
                },
                {
                    name: this.$t("pages.module.teacherBookRequest.cols.corporationName"),
                    key: "corporation_name",
                },
                {
                    name: this.$t("pages.module.teacherBookRequest.cols.branch"),
                    key: "branch",
                },
                {
                    name: this.$t("pages.module.teacherBookRequest.cols.createdAt"),
                    scopedSlots: {customRender: "createdAt"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            selectedRowKeys: [],
            form: {
                title: '',
                data: {}
            },
            exportLoading: false,
            filterParameters: {},
        }
    },
    computed: {
        table() {
            return this.$store.state.module.teacherBookRequest.table;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.teacherBookRequest.title"), [this.$t("menu.moduleManagement")]);

        this.filterParameters = Object.assign(this.$root.getFilterWithUrl({}), {
            sort: 'created_at:desc'
        });

        this.$store.dispatch('module/teacherBookRequest/get', {
            page: {},
            filterData: this.filterParameters
        });
    },
    methods:{
        fetchRecord(record) {
            this.form.title = this.$t("pages.module.teacherBookRequest.detail");

            this.axios.get(this.endpoints['module_teacher_book_request'] + '/' + record.id).then(response => {
                let data = response.data.data;
                this.form.data = data;
                this.showModal(this.$refs.requestDetailModal);
            });
        },
        deleteRecord(id){
            this.$store.dispatch("module/teacherBookRequest/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/teacherBookRequest/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.filterParameters = Object.assign({
                sort: 'created_at:desc',
            }, filterData);

            this.$store.dispatch("module/teacherBookRequest/get", {
                page: pagination,
                filterData: this.filterParameters
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        exportRequest(){
            this.exportLoading = true;
            this.axios({
                url: this.endpoints['module_teacher_book_request_export'],
                method: 'get',
                responseType: "blob",
                params: this.filterParameters
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>